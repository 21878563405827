<template>
  <div>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <CoinflipCardList />
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12" class="pt-0 px-0">
        <div class="pt-0" v-if="$vuetify.breakpoint.xsOnly">
          <MobileGames />
        </div>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import CoinflipCardList from "../components/coinflip/CardList.vue";

export default {
  components: {
    CoinflipCardList,
  },
};
</script>

<style>
#keep .v-navigation-drawer__border {
  display: none;
}

.slanted {
  position: relative;
  display: inline-block;
  padding: 1em 5em 1em 1em;
  overflow: hidden;
  background-color: #f5f5f5 !important;
}

.slanted:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #616161;
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: skew(-35deg);
  -ms-transform: skew(-35deg);
  transform: skew(-35deg);
  z-index: 1;
}

.v-stepper {
  background-color: #f5f5f5 !important;
}

.v-stepper__step__step {
  padding: 1.5rem;
}

.stepper-custom-divider {
  margin: 45px -67px 0 !important;
}

.v-stepper__step {
  padding: 10px !important;
  padding-top: 20px !important;
}

.sprite-img {
  opacity: 0;
  width: 0;
  height: 0px;
  display: inline;
}

@media screen and (max-width: 425px) {
  .create-game-padding {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
