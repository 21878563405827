<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <button class="sort-button" v-bind="attrs" v-on="on">
                {{ sort.selectedSortName ? sort.selectedSortName.label : 'By Name' }}
            </button>
        </template>
        <v-list color="#161E1D">
            <v-list-item @click="selectSortName(null)" link>
                <v-list-item-title class="text-center">Default</v-list-item-title>
            </v-list-item>
            <v-list-item @click="selectSortName(item)" link v-for="(item, index) in ByName" :key="index">
                <v-list-item-title class="text-center">{{ item.label }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
export default {
    data: () => ({
        sort: {
            selectedSortName: null,
        },
        ByName: [
            { label: "A-Z" },
            { label: "Z-A" },
        ],
    }),
    methods: {
        selectSortName(value) {
            this.sort.selectedSortName = value
            this.$emit('selectedSortName', value)
        },
    }
}
</script>

<style scoped>
.sort-coin-container {

    display: flex;
    align-items: center;
    gap: 7px;
}

.sort-coin-container .sort-button {
    width: 100px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    background-color: #161E1D;
    border-radius: 10px;
    padding: 11px 6px;
    width: 150px;
    color: #fff;
}
</style>