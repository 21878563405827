<template>
  <div class="pa-0 mt-3">
    <div v-if="$vuetify.breakpoint.xsOnly" class="px-2 my-2">
      <CreateGame></CreateGame>
      <v-tabs v-model="meta.gameFilter" background-color="transparent" class="px-2 mt-2" color="default" grow>
        <v-tab href="#1">My Games </v-tab>
        <v-tab href="#2">Open Games </v-tab>
      </v-tabs>
      <v-row>
        <v-col class="px-0">
          <div class="py-3 pt-0">
            <CoinFilter @selectedCoin="selectCoin"></CoinFilter>
          </div>
          <div class="sort-coin-container mx-2">
            <div>Sort:</div>
            <SortByAmount @selectedSortRange="selectSortRange"></SortByAmount>
            <SortByName @selectedSortName="selectSortName"></SortByName>
          </div>
          <div class=" py-3 pb-0">
            <ShowGames @selectedShowGames="(showGames) => meta.showGames = showGames.label"></ShowGames>
          </div>
        </v-col>
      </v-row>
    </div>

    <div v-if="!$vuetify.breakpoint.xsOnly" class="d-flex justify-center items-center justify-space-between align-center">
      <div class="d-flex align-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <button class="headline ml-2 mb-0 font-bold d-flex  align-center" v-bind="attrs" v-on="on">
              <span> {{ GetGameLabel }} </span>
              <v-icon class="ml-3">mdi-chevron-down</v-icon>
            </button>
          </template>
          <v-list color="#161E1D">
            <v-list-item @click="meta.gameFilter = 2" link>
              <v-list-item-title class="text-center">Open Games</v-list-item-title>
            </v-list-item>
            <v-list-item @click="meta.gameFilter = 1" link>
              <v-list-item-title class="text-center">My Games</v-list-item-title>
            </v-list-item>

          </v-list>
        </v-menu>

        <div class="ml-4">
          <ShowGames @selectedShowGames="(showGames) => meta.showGames = showGames.label"></ShowGames>
        </div>
      </div>
      <div class="d-flex align-center">
        <CoinFilter @selectedCoin="selectCoin"></CoinFilter>
        <div class="sort-coin-container ml-4">
          <div>Sort:</div>
          <SortByAmount @selectedSortRange="selectSortRange"></SortByAmount>
          <SortByName @selectedSortName="selectSortName"></SortByName>
        </div>
      </div>
    </div>

    <v-sheet class="pa-0" style="background-color: transparent;">
      <v-progress-linear color="green accent-3" indeterminate rounded height="3" v-if="loading"></v-progress-linear>
      <v-row v-else>
        <v-col cols="12" class="py-0 px-0">
          <v-row>
            <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12" class="py-0 px-2"
              v-for="(coinflip, index) in lobbySlots" :key="`coinflip-${index}`"
              :id="`${coinflip._id ? coinflip._id : null}`">
              <game-card v-if="coinflip._id" :coinflip="coinflip"
                :owned="coinflip.lobbyCreatedBy._id == user.id ? true : false"
                @removeGameCard="removeGameCard"></game-card>
              <v-card v-else-if="lobbies.length" class="my-2 game-card-bg-color1"> </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" v-if="lobbies.length == 0" class="text-center">
          NO OPEN COINFLIPS
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GameCard from "./GameCard";
import CoinFilter from "../filters/CoinFilter";
import SortByAmount from "../filters/SortByAmount";
import SortByName from "../filters/SortByName";
import ShowGames from "../filters/ShowGames";
import CreateGame from "./CreateGame";
export default {
  components: {
    GameCard,
    CoinFilter,
    SortByAmount,
    SortByName,
    ShowGames,
    CreateGame
  },
  data: () => ({
    loading: false,
    timeout: null,
    lobbySlots: [],
    sort: {
      selectedAmountRange: null,
      selectedSortName: null,
    },
    meta: {
      gameFilter: 2,
      showGames: 10,
      minAmount: 10,
      maxAmount: 50000,
      coins: [0, 1],
    },
  }),
  computed: {
    ...mapState("lobby", ["lobbies", "coinSelected"]),
    ...mapState("auth", ["user"]),
    filterName: function () {
      return this.coinSelected == 3 ? "All" : this.coinSelected == 0 ? "Heads" : "Tails";
    },
    GetGameLabel: function () {
      if (this.meta.gameFilter == 2) {
        return "Open Games";
      } else if (this.meta.gameFilter == 1) {
        return "My Games";
      }
      return "Open Games";
    },

  },
  watch: {
    "meta.showGames": function () {
      const current = this.meta.showGames - this.lobbySlots.length;
      if (current > 0) {
        Array.from(Array(current).keys()).forEach(() => {
          this.lobbySlots.push({
            _id: null,
          });
        });
        this.initLobbyToEmptySlots();
      } else {
        this.lobbySlots = this.lobbySlots.slice(0, this.meta.showGames);
      }
    },
    lobbies: function () {
      this.initLobbyToEmptySlots();

    },
    coinSelected: function () {
      this.meta.coins = this.coinSelected == 3 ? [0, 1] : this.coinSelected;
      this.getActiveCoinFlips(false, true);
    },
    "sort.selectedAmountRange": function () {
      this.meta.minAmount = this.sort.selectedAmountRange ? this.sort.selectedAmountRange.min : 20;
      this.meta.maxAmount = this.sort.selectedAmountRange ? this.sort.selectedAmountRange.max : 999999999;
      this.getActiveCoinFlips(false, true);
    },
    "sort.selectedSortName": function () {
      this.getActiveCoinFlips(false, true);
    },
    "meta.gameFilter": function () {
      this.getActiveCoinFlips(false, true);
    },
  },
  methods: {

    selectSortRange(item) {
      this.sort.selectedAmountRange = item

    },
    selectSortName(item) {
      this.sort.selectedSortName = item
    },
    removeGameCard(coinflipId) {
      this.lobbySlots = this.lobbySlots.map((lobby) => {
        if (lobby._id == coinflipId) {
          return {
            _id: null,
          };
        }
        return lobby;
      });
    },
    initLobbyToEmptySlots() {
      this.lobbies.map((lobby) => {
        if (lobby.status == 0) {
          const index = this.lobbySlots.findIndex((lobby1) => lobby1._id == null && !this.lobbySlots.find((lobby2) => lobby2._id == lobby._id));
          if (index >= 0 && this.lobbySlots[index]._id == null) {
            const owner = this.meta.gameFilter == 1 && lobby.lobbyCreatedBy._id == this.user.id;
            const opponent = this.meta.gameFilter == 2 && lobby.lobbyCreatedBy._id != this.user.id;
            if (owner || opponent) {
              this.lobbySlots[index] = lobby;
            }
          }
        } else {
          const index = this.lobbySlots.findIndex((lobby1) => lobby1._id == lobby._id);
          if (index >= 0) {
            this.lobbySlots[index] = lobby
          }
        }
      });
    },
    initLobbySlots() {
      this.lobbySlots = [];
      Array.from(Array(this.meta.showGames).keys()).forEach(() => {
        this.lobbySlots.push({
          _id: null,
        });
      });
    },

    selectCoin(value) {
      if (this.selectCoin == value) return;
      this.$store.commit("lobby/SET_SELECTED_COIN", value);
    },
    async getActiveCoinFlips(loadMore = false, newData = false) {
      this.initLobbySlots();
      if (!loadMore) this.loading = true;

      await this.$store.dispatch("lobby/getOpenActiveLobbies", {
        key: this.$route.params.key,
        meta: this.meta,
        loadMore,
        newData,
        sortName: this.sort.selectedSortName
      });
      this.loading = false;
    },
  },
  async mounted() {
    this.meta.gameFilter = 1
    this.getActiveCoinFlips(false, true);
    this.meta.gameFilter = 2
  },
};
</script>

<style scoped>
.sort-coin-container {
  display: flex;
  align-items: center;
  gap: 7px;
}



.headline {
  border-radius: 20px;
  background-color: #161E1D;
  padding: 10px 20px;
  font-size: 18.8px;
}



.game-card-bg-color1 {
  background: #161A19;
  border-radius: 16px;
  height: 196px;
  margin-bottom: 16px;
}

.font-bold {
  font-weight: 700;
}

.small-radio i {
  font-size: 19px;
}

.small-radio label {
  font-size: 12px;
  padding-left: 0px;
  margin-left: -4px;
}

.small-radio .v-radio {
  padding: 0px;
}

.small-radio [class*="__ripple"] {
  left: 0;
}

.vs-container {
  width: 100%;
}

.avatar-container {
  width: 60px;
}

.player-name {
  font-size: 12px;
  font-family: sans-serif;
}

.lobby-card {
  border: 1px solid #434343;
}

.settled {
  -webkit-animation: fadeout 2s linear forwards;
  animation: fadeout 2s linear forwards;
}

@keyframes fadeout {
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@-webkit-keyframes fadeout {
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}
</style>
