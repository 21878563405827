<template>
    <div>
        <span>Show Games:</span>
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <button class="show-games-button ml-2" v-bind="attrs" v-on="on">
                    <span>{{ meta.showGames }}</span>
                    <v-icon class="ml-1">mdi-chevron-down</v-icon>
                </button>
            </template>
            <v-list color="#161E1D">

                <v-list-item @click="selectShowGames(item)" link v-for="(item, index) in ShowGames" :key="index">
                    <v-list-item-title class="text-center">{{ item.label }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    data: () => ({
        meta: {
            showGames: 10,
        },
        ShowGames: [
            { label: 10 },
            { label: 20 },
            { label: 30 },
            { label: 40 },
            { label: 50 },
        ],
    }),
    methods: {
        selectShowGames(value) {
            this.meta.showGames = value.label
            this.$emit('selectedShowGames', value)
        },
    }
}
</script>

<style scoped>
.show-games-button {
    background-color: #161E1D;
    border-radius: 10px;
    padding: 4px 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
}
</style>