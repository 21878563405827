<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <button class="sort-button" v-bind="attrs" v-on="on">
                {{ sort.selectedAmountRange ? sort.selectedAmountRange.label : 'By Amount' }}
            </button>
        </template>
        <v-list color="#161E1D">
            <v-list-item @click="selectSortRange(null)" link>
                <v-list-item-title class="text-center">Default</v-list-item-title>
            </v-list-item>
            <v-list-item @click="selectSortRange(item)" link v-for="(item, index) in ByAmountRange" :key="index">
                <v-list-item-title class="text-center">{{ item.label }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    data: () => ({
        sort: {
            selectedAmountRange: null,
        },
        ByAmountRange: [
            { min: 20, max: 500, label: "20.00 - 500.00" },
            { min: 500, max: 5000, label: "500.00 - 5,000.00" },
            { min: 5000, max: 999999999, label: "5,000.00 +" },
        ],
    }),
    methods: {
        selectSortRange(value) {
            this.sort.selectedAmountRange = value
            this.$emit('selectedSortRange', value)
        },
    }
}
</script>


<style scoped>
.sort-coin-container .sort-button {
    width: 100px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    background-color: #161E1D;
    border-radius: 10px;
    padding: 11px 6px;
    width: 150px;
    color: #fff;
}
</style>