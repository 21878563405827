<template>
    <div class="filter-coin-container">
        <button @click="selectCoin(3)" :class="`${coinSelected == 3 ? 'active' : ''} d-flex justify-center items-center`">
            <img :src="require('../../assets/images/allCoin.png')" width="20" alt="">
            <div class="ml-2">ALL</div>
        </button>
        <button @click="selectCoin(1)" :class="`${coinSelected == 1 ? 'active' : ''} d-flex justify-center items-center`">
            <img :src="require('../../assets/images/headCoin.svg')" width="20" alt="">
            <div class="ml-2">HEADS</div>
        </button>
        <button @click="selectCoin(0)" :class="`${coinSelected == 0 ? 'active' : ''} d-flex justify-center items-center`">
            <img :src="require('../../assets/images/tailCoin.svg')" width="20" alt="">
            <div class="ml-2">TAILS</div>
        </button>
    </div>
</template>

<script>
export default {
    props: ['owner'],
    data() {
        return {
            coinSelected: 3
        }
    },
    methods: {
        selectedCoin(coin) {
            if (this.owner) {
                if (coin == 1 && this.coinSelected == 0) {
                    return true
                } else if (coin == 0 && this.coinSelected == 1) {
                    return true
                } else if (coin == 3) {
                    return true
                } else {
                    return false
                }
            }
            return this.coinSelected == coin
        },
        selectCoin(value) {
            let currentValue = value
            if (this.owner) {
                if (value == 1) {
                    currentValue = 0
                } else if (value == 0) {
                    currentValue = 1
                }

            }
            this.coinSelected = value
            this.$emit('selectedCoin', currentValue)
        }
    }
}


</script>

<style scoped>
.filter-coin-container {
    background-color: #161E1D;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 6px;
}

.filter-coin-container button {
    width: 100px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
}


.filter-coin-container .active {
    background-color: #134A43;
    border-radius: 20px;
    padding: 5px 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
}
</style>